import * as React from "react";
import {
  required,
  List, 
  Datagrid, 
  EditButton,
  Show,
  Edit,
  Create,
  Filter,
  TextField,
  BooleanField,
  TextInput,
  BooleanInput,
  BulkDeleteButton,
  FormWithRedirect,
  Toolbar,
  NumberField,
  NumberInput
} from 'react-admin';
import { JsonField, JsonInput } from "react-admin-json-view";
import { Box, Typography, Breadcrumbs, Link, Icon } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import { customStyle } from '../components/style';
import FormWrapper from "../components/formWrapper";

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

export const BlockList = props => {
  const { options } = props;

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ options.title ? options.title : props.resource} />
      <RaBox display="flex">
        <RaBox flex={12}>
          <List {...props} filters={<BlockFilter />} title={props.title} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
              <TextField source="name" label="Name" />
              <TextField source="code" label="Code" />
              <NumberField source="priority" label="Priority" />
              <BooleanField source="status" />
              <EditButton />
            </Datagrid>
          </List>
        </RaBox>
      </RaBox>
    </BoxedShowLayout>
  )
};

export const BlockCreate = props => {
  return (
    <FormWrapper name="Add Block" {...props}>
      <Create {...props}>
        <BlockCreateForm />
      </Create>
    </FormWrapper>
  );
}

const BlockCreateForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex" >
              <Box flex={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href="#/blocks">
                    <Icon className="fa fa-chevron-left" fontSize="small" /> Back
                  </Link>
                  <Typography color="textPrimary">{props.options && props.options.title ? props.options.title : props.resource}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="name" label="Name" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="code" label="Code" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <NumberInput source="priority" label="Priority" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <JsonInput source="options" label="Options (Json)" jsonString={false} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <BooleanInput label="Status" source="status" defaultValue={true} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="blocks"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const BlockEdit = props => {
  return (
    <FormWrapper name="Edit Block" {...props}>
      <Edit mutationMode="pessimistic" {...props}>
        <BlockEditForm />
      </Edit>
    </FormWrapper>
  );
}

const BlockEditForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex" >
              <Box flex={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href="#/galleries">
                    <Icon className="fa fa-chevron-left" fontSize="small" /> Back
                  </Link>
                  <Typography color="textPrimary">{props.options && props.options.title ? props.options.title : props.resource}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="name" label="Name" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="code" label="Code" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <NumberInput source="priority" label="Priority" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <JsonInput source="options" label="Options (Json)" jsonString={false} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <BooleanInput label="Status" source="status" defaultValue={true} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="blocks"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const BlockShow = (props) => {
  const classes = customStyle();

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
      <Show {...props}>
        <BoxedShowLayout>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <TextField source="id" />
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <TextField source="name" label="Name"/>
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <TextField source="code" label="Code" />
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <NumberField source="priority" label="Priority" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <Typography className={`${classes.label}`} variant="caption" display="block" gutterBottom>Options</Typography>
              <JsonField source="options" jsonString={false} label="Options (Json)" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <BooleanField source="status" label="Status" />
            </RaBox>
          </RaBox>
        </BoxedShowLayout>
      </Show>
    </BoxedShowLayout>
  );
}

export const BlockFilter= props => (
  <Filter { ...props }>
    <TextInput label="Search" source="keyword" alwaysOn />
  </Filter >
);
