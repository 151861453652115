import * as React from "react";
import {
  required,
  List, 
  Datagrid, 
  EditButton,
  Show,
  Edit,
  Create,
  Filter,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
  BulkDeleteButton,
  FormWithRedirect,
  Toolbar
} from 'react-admin';
import { Box } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import FormWrapper from "../components/formWrapper";

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

export const CategoryList = props => {
  const { options } = props;

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ options.title ? options.title : props.resource} />
      <RaBox display="flex">
        <RaBox flex={12}>
          <List {...props} filters={<CategoryFilter />} title={props.title} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
              <TextField source="name" label="Name" />
              <TextField source="slug" label="Slug" />
              <TextField source="description" label="Description" />
              <BooleanField source="status" />
              <EditButton />
            </Datagrid>
          </List>
        </RaBox>
      </RaBox>
    </BoxedShowLayout>
  )
};

export const CategoryCreate = props => {
  return (
    <FormWrapper name="Add Category" {...props}>
      <Create {...props}>
        <CategoryCreateForm />
      </Create>
    </FormWrapper>
  );
}

const CategoryCreateForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="name" label="Name" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" defaultValue={""} multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="meta_keywords" label="Meta Keywords" fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="meta_description" label="Meta Description" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <BooleanInput label="Status" source="status" defaultValue={true} />
              </Box>
            </Box>
          </Box>
          
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="categories"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const CategoryEdit = props => {
  return (
    <FormWrapper name="Edit Category" {...props}>
      <Edit mutationMode="pessimistic" {...props}>
        <CategoryEditForm />
      </Edit>
    </FormWrapper>
  );
}

const CategoryEditForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="name" label="Name" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="slug" label="Slug" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="meta_keywords" label="Meta Keywords" fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="meta_description" label="Meta Description" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <BooleanInput label="Status" source="status" defaultValue={true} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="categories"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const CategoryShow = (props) => (
  <BoxedShowLayout>
    <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
    <Show {...props}>
      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox pr="12px" flex={1}>
            <TextField source="id" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="name" label="Name"/>
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="description" label="Description" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="meta_keywords" label="Meta Keywords" />
          </RaBox>
          <RaBox pr="12px" flex={1}>
            <TextField source="meta_description" label="Meta Description" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <BooleanField source="status" label="Status" />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </Show>
  </BoxedShowLayout>
);

export const CategoryFilter= props => (
  <Filter { ...props }>
    <TextInput label="Search" source="keyword" alwaysOn />
  </Filter >
);
