import * as React from "react";
import {
  required,
  useCreateSuggestionContext,
  useCreate,
  List, 
  Datagrid, 
  EditButton,
  Show,
  Edit,
  Create,
  Filter,
  TextField, 
  TextInput,
  ImageInput,
  ImageField,
  BulkDeleteButton,
  FormWithRedirect,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  Toolbar
} from 'react-admin';
import { 
  Box, 
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField as MuiTextField,
  Button
} from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import FormWrapper from "../components/formWrapper";

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

export const ProjectList = props => {
  const { options } = props;

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ options.title ? options.title : props.resource} />
      <RaBox display="flex">
        <RaBox flex={12}>
          <List {...props} filters={<ProjectFilter />} title={props.title} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
              <TextField source="title" label="Title" />
              <TextField source="subtitle" label="Subtitle" />
              <ReferenceArrayField label="Types" reference="types" source="types">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
              <ImageField source="feature_image" src="url" title="Image" />
              <EditButton />
            </Datagrid>
          </List>
        </RaBox>
      </RaBox>
    </BoxedShowLayout>
  )
};

export const ProjectCreate = props => {
  return (
    <FormWrapper name="Add Project" {...props}>
      <Create {...props}>
        <ProjectCreateForm />
      </Create>
    </FormWrapper>
  );
}

const ProjectCreateForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="title" label="Title" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="subtitle" label="Subtitle" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" defaultValue={""} multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                  <ReferenceArrayInput source="types" reference="types">
                        <AutocompleteArrayInput create={<CreateType />} fullWidth />
                  </ReferenceArrayInput>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <ImageInput source="feature_image" label="Featured Image" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended size: 350 x 500px
                </Typography>
              </Box>
              <Box pl="12px" flex={4}>
                <ImageInput source="images" multiple label="Images" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="projects"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

const CreateType = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || '');
  const [create] = useCreate();

  const handleSubmit = event => {
      event.preventDefault();
      if (value.trim() !== '') {
        create(
          'types',
          {
            name: value,              
          },
          {
              onSuccess: (res) => {
                  setValue('');
                  onCreate(res.data);
              },
          }
        );
      } else {
        alert("Please input the type name!");
      }
      
  };

  return (
      <Dialog open onClose={onCancel}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>{"Create new type"}</DialogTitle>
            <DialogContent>
                <MuiTextField
                    label="Name"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    autoFocus
                />
            </DialogContent>
            <DialogActions>
                <Button type="submit">Save</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </DialogActions>
          </form>
      </Dialog>
  );
};

export const ProjectEdit = props => {
  return (
    <FormWrapper name="Edit Project" {...props}>
      <Edit mutationMode="pessimistic" {...props}>
        <ProjectEditForm />
      </Edit>
    </FormWrapper>
  );
}

const ProjectEditForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="title" label="Title" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="subtitle" label="Subtitle" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                  <ReferenceArrayInput source="types" reference="types">
                        <AutocompleteArrayInput create={<CreateType />} fullWidth />
                  </ReferenceArrayInput>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <ImageInput source="feature_image" format={formatUrl} label="Feature Image" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended size: 350 x 500px
                </Typography>
              </Box>
              <Box pl="12px" flex={4}>
                <ImageInput source="images" multiple label="Images" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
              </Box>
            </Box>
          </Box></Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="projects"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const ProjectShow = (props) => (
  <BoxedShowLayout>
    <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
    <Show {...props}>
      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox pr="12px" flex={1}>
            <TextField source="id" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={2}>
            <TextField source="title" label="Title"/>
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={2}>
            <TextField source="subtitle" label="Subtitle"/>
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="description" label="Description" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <ReferenceArrayField label="Types" reference="types" source="types">
              <SingleFieldList>
                  <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={2}>
            <ImageField source="feature_image" title="name" />
          </RaBox>
          <RaBox pl="12px" flex={4}>
            <ImageField source="images" src="url" title="name" />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </Show>
  </BoxedShowLayout>
);

export const ProjectFilter= props => (
  <Filter { ...props }>
    <TextInput label="Search" source="keyword" alwaysOn />
  </Filter >
);

function formatUrl(value) {
  if (!value ||  typeof value === "string") {
    return { url: value };
  } else {
    return value;
  }
}
