import * as React from "react";
import {
  required,
  List, 
  Datagrid, 
  EditButton,
  Show,
  Edit,
  Create,
  Filter,
  TextField, 
  TextInput,
  ImageInput,
  ImageField,
  BulkDeleteButton,
  FormWithRedirect,
  Toolbar,
  BooleanField,
  BooleanInput
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import FormWrapper from "../components/formWrapper";

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

export const GalleryList = props => {
  const { options } = props;

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ options.title ? options.title : props.resource} />
      <RaBox display="flex">
        <RaBox flex={12}>
          <List {...props} filters={<GalleryFilter />} title={props.title} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
              <TextField source="title" label="Title" />
              <TextField source="code" label="Codee" />
              <ImageField source="feature_image" src="url" title="Image" />
              <BooleanField source="status" label="Status" />
              <EditButton />
            </Datagrid>
          </List>
        </RaBox>
      </RaBox>
    </BoxedShowLayout>
  )
};

export const GalleryCreate = props => {
  return (
    <FormWrapper name="Add Gallery" {...props}>
      <Create {...props}>
        <GalleryCreateForm />
      </Create>
    </FormWrapper>
  );
}

const GalleryCreateForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="title" label="Title" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="code" label="Code" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" defaultValue={""} multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <ImageInput source="feature_image" label="Featured Image" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended size: 200 x 200px
                </Typography>
              </Box>
              <Box pl="12px" flex={4}>
                  <ImageInput source="images" multiple label="Images" validate={required()} accept="image/*">
                    <ImageField source="url" title="name" />
                  </ImageInput>
                  <Typography variant="caption" display="block" gutterBottom>
                    Recommended size: 200 x 200px
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <BooleanInput source="status" label="Status" defaultValue={true} fullWidth />
                </Box>
              </Box>
            </Box>
          </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="gallery"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const GalleryEdit = props => {
  return (
    <FormWrapper name="Edit Gallery" {...props}>
      <Edit mutationMode="pessimistic" {...props}>
        <GalleryEditForm />
      </Edit>
    </FormWrapper>
  );
}

const GalleryEditForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="title" label="Title" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <TextInput source="code" label="Code" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <ImageInput source="feature_image" format={formatUrl} label="Feature Image" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended size: 200 x 200px
                </Typography>
              </Box>
              <Box pl="12px" flex={4}>
                <ImageInput source="images" multiple label="Images" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended size: 200 x 200px
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <BooleanInput source="status" label="Status" defaultValue={true} fullWidth />
                </Box>
              </Box>
            </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="gallery"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const GalleryShow = (props) => (
  <BoxedShowLayout>
    <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
    <Show {...props}>
      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox pr="12px" flex={1}>
            <TextField source="id" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={2}>
            <TextField source="title" label="Title"/>
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="description" label="Description" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <ImageField source="feature_image" title="name" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <ImageField source="images" src="url" title="name" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <BooleanField source="status" label="Status" />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </Show>
  </BoxedShowLayout>
);

export const GalleryFilter= props => (
  <Filter { ...props }>
    <TextInput label="Search" source="keyword" alwaysOn />
  </Filter >
);

function formatUrl(value) {
  if (!value ||  typeof value === "string") {
    return { url: value };
  } else {
    return value;
  }
}
