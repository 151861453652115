import { defaultTheme } from "react-admin";
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  ...defaultTheme,
  ...{
    palette: {
      type: 'light',
      primary: {
        light: '#d65e6a',
        main: '#dc3545',
        dark: '#852029'
      },
      secondary: {
        light: '#7387F5',
        main: '#edf7ff',
        dark: '#273DC8'
      },
    },
  }
});

export default theme;