import * as React from "react";
import {
    required,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SelectInput,
    NumberField,
    ImageInput,
    ImageField,
    FormDataConsumer,
    TextInput,
    NumberInput,
    RichTextField,
    BulkDeleteButton,
    FormWithRedirect,
    Toolbar
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { JsonField, JsonInput } from "react-admin-json-view";
import { Box } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import { SETTINGS_CHOICE } from '../constants/constants';
import FormWrapper from "../components/formWrapper";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const SettingList = props => (
    <BoxedShowLayout>
        <Breadcrumb title={ props.options.title ? props.options.title : props.resource} />
        <RaBox display="flex">
            <RaBox flex={12}>
            <List {...props} filters={<SettingFilter />} title="Setting" bulkActionButtons={<BulkActionButtons />}>
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <TextField source="group" />
                    <TextField source="code" />
                    <TextField source="type" />
                    <TextField source="value" />
                    <EditButton />
                </Datagrid>
            </List>
            </RaBox>
        </RaBox>
    </BoxedShowLayout>
);

export const SettingShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <BoxedShowLayout>
            <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
            <Show {...props}>
                <BoxedShowLayout>
                    <RaBox display="flex" >
                        <RaBox pr="12px" flex={1}>
                            <TextField source="id" />
                        </RaBox>
                    </RaBox>
                    <RaBox display="flex" >
                        <RaBox pr="12px" flex={1}>
                            <TextField source="name" />
                        </RaBox>
                        <RaBox pr="12px" flex={1}>
                            <TextField source="code" />
                        </RaBox>
                        <RaBox pr="12px" flex={1}>
                            <TextField source="group" />
                        </RaBox>
                        <RaBox pr="12px" flex={1}>
                            <TextField source="type" />
                        </RaBox>
                    </RaBox>
                    <RaBox display="flex" >
                        <RaBox pr="12px" flex={1}>
                            {
                                (record.type === 'text' &&
                                    <TextField source="value" />)
                                || (record.type === 'number' &&
                                    <NumberField source="value" />)
                                || (record.type === 'image' &&
                                    <ImageField source="value" title="name" />)
                                || (record.type === 'json' &&
                                    <JsonField source="value" addLabel={true} jsonString={false} label="Value" />)
                                || (record.type === 'html' &&
                                    <RichTextField source="value" label="Value" />)
                            }
                        </RaBox>
                    </RaBox>
                </BoxedShowLayout>
            </Show>
        </BoxedShowLayout>
    );
}

export const SettingEdit = props => {
    return (
        <FormWrapper name="Edit Setting" {...props}>
            <Edit mutationMode="pessimistic" {...props}>
                <SettingEditForm />
            </Edit>
        </FormWrapper>
    );
}

const SettingEditForm = props => (
    <FormWithRedirect
        {...props}
        render={formProps => (
            // here starts the custom form layout
            <form>
                <Box p="1em">
                    <Box pl="24px" pr="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={1}>
                                <TextInput source="name" validate={required()} fullWidth />
                            </Box>
                            <Box pr="12px" flex={1}>
                                <TextInput source="code" validate={required()} fullWidth />
                            </Box>
                            <Box pr="12px" flex={1}>
                                <TextInput source="group" validate={required()} fullWidth />
                            </Box>
                            <Box flex={1}>
                                <SelectInput source="type" choices={SETTINGS_CHOICE} optionText="name" optionValue="id" validate={required()} fullWidth />
                            </Box>
                        </Box>
                    </Box>
                    <Box pl="24px" pr="24px" pt="24px">
                        <Box display="flex">
                            <Box flex={1}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => (formData.type === 'text' &&
                                        <TextInput source="value" validate={required()} fullWidth {...rest} />)
                                        || (formData.type === 'number' &&
                                            <NumberInput source="value" validate={required()} fullWidth {...rest} />)
                                        || (formData.type === 'image' &&
                                            <ImageInput source="value" validate={required()} format={formatUrl} accept="image/*"  {...rest} >
                                                <ImageField source="url" title="name" />
                                            </ImageInput>)
                                        || (formData.type === 'json' &&
                                            <JsonInput source="value" label="Value" validate={required()} {...rest} />)
                                        || (formData.type === 'html' &&
                                            <RichTextInput rows="3" source="value" label="Value" validate={required()} fullWidth {...rest} />)
                                    }
                                </FormDataConsumer>
                            </Box>
                        </Box>
                    </Box></Box>
                <Toolbar
                    record={formProps.record}
                    basePath={formProps.basePath}
                    invalid={formProps.invalid}
                    handleSubmit={formProps.handleSubmit}
                    saving={formProps.saving}
                    pristine={formProps.pristine}
                    resource="setting"
                    mutationMode="pessimistic"
                />
            </form>
        )}
    />
);

export const SettingCreate = props => {
    return (
        <FormWrapper name="Add Setting" {...props}>
            <Create {...props}>
                <SettingCreateForm />
            </Create>
        </FormWrapper>
    );
}

const SettingCreateForm = props => (
    <FormWithRedirect
        {...props}
        render={formProps => (
            // here starts the custom form layout
            <form>
                <Box p="1em">
                    <Box pl="24px" pr="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={1}>
                                <TextInput source="name" validate={required()} fullWidth />
                            </Box>
                            <Box pr="12px" flex={1}>
                                <TextInput source="code" validate={required()} fullWidth />
                            </Box>
                            <Box pr="12px" flex={1}>
                                <TextInput source="group" validate={required()} fullWidth />
                            </Box>
                            <Box flex={1}>
                                <SelectInput source="type" choices={SETTINGS_CHOICE} optionText="name" optionValue="id" validate={required()} fullWidth />
                            </Box>
                        </Box>
                    </Box>
                    <Box pl="24px" pr="24px" pt="24px">
                        <Box display="flex">
                            <Box flex={1}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => (formData.type === 'text' &&
                                        <TextInput source="value" validate={required()} fullWidth {...rest} />)
                                        || (formData.type === 'number' &&
                                            <NumberInput source="value" validate={required()} fullWidth {...rest} />)
                                        || (formData.type === 'image' &&
                                            <ImageInput source="value" validate={required()} accept="image/*"  {...rest} >
                                                <ImageField source="url" title="name" />
                                            </ImageInput>)
                                        || (formData.type === 'json' &&
                                            <JsonInput source="value" label="Value" validate={required()} {...rest} />)
                                        || (formData.type === 'html' &&
                                            <RichTextInput rows="3" source="value" label="Value" validate={required()} fullWidth {...rest} />)
                                    }
                                </FormDataConsumer>
                            </Box>
                        </Box>
                    </Box></Box>
                <Toolbar
                    record={formProps.record}
                    basePath={formProps.basePath}
                    invalid={formProps.invalid}
                    handleSubmit={formProps.handleSubmit}
                    saving={formProps.saving}
                    pristine={formProps.pristine}
                    resource="setting"
                    mutationMode="pessimistic"
                />
            </form>
        )}
    />
);

export const SettingFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
    </Filter >
);

function formatUrl(value) {
    if (!value ||  typeof value === "string") {
      return { url: value };
    } else {
      return value;
    }
}