import * as React from 'react';
import Breadcrumb from '../components/breadcrumb';
import { customStyle } from '../components/style';


const FormWrapper = (props) => {
    const classes = customStyle();
    const { name, resource } = props;
    return (
        <div className={`${classes.ml16}`}>
            <Breadcrumb title={ name } heading="Back" headingLink={ `#/${resource}` } />
            <div className={`form-wrapper-content`}>
                { props.children }
            </div>
        </div>
    );
};

export default FormWrapper;