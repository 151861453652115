import * as React from 'react';
import { Typography, Breadcrumbs, Link, Icon } from '@material-ui/core';
import { RaBox } from 'ra-compact-ui';
import { customStyle } from './style';


const Breadcrumb = ({ title, subMenu = '', subMenuLink = '', heading = 'Home', headingLink = '/' }) => {
    const classes = customStyle();

    return (
        <RaBox display="flex" >
            <RaBox flex={12}>
                <Breadcrumbs separator="" aria-label="breadcrumb">
                    <Link className={`${classes.primaryColor} ${classes.textUppercase}`} href={ headingLink }>
                        <Icon className={`${classes.smallFont} fa fa-chevron-left`} /> { heading }
                    </Link>
                    {subMenu !== '' &&
                        <Link className={`${classes.primaryColor} ${classes.textUppercase}`} href={ subMenuLink }>
                            { subMenu }
                        </Link>
                    }
                    <Typography color="textPrimary" className={`${classes.primaryColor} ${classes.textUppercase}`}>{ title }</Typography>
                </Breadcrumbs>
            </RaBox>
        </RaBox>
    );
};

export default Breadcrumb;