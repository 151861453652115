import * as React from "react";
import {
  required,
  List, 
  Datagrid, 
  EditButton,
  Show,
  Edit,
  Create,
  Filter,
  TextField, 
  TextInput,
  DateField,
  DateTimeInput,
  RichTextField,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  ChipField,
  ImageInput,
  ImageField,
  BulkDeleteButton,
  FormWithRedirect,
  Toolbar
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { JsonField, JsonInput } from "react-admin-json-view";
import { Box, Typography, Breadcrumbs, Link, Icon } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import { customStyle } from '../components/style';
import FormWrapper from "../components/formWrapper";

var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike', 'link', 'image'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean']
];

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

export const PostList = props => {
  const { options } = props;

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ options.title ? options.title : props.resource} />
      <RaBox display="flex">
        <RaBox flex={12}>
          <List {...props} filters={<PostFilter />} title={props.title} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
              <TextField source="title" label="Title" />
              <TextField source="slug" label="Slug" />
              <ReferenceArrayField label="Categories" reference="categories" source="categories">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
              <DateField source="published_at" showTime />
              <BooleanField source="status" />
              <EditButton />
            </Datagrid>
          </List>
        </RaBox>
      </RaBox>
    </BoxedShowLayout>
  )
};

export const PostCreate = props => {
  return (
    <FormWrapper name="Add Post" {...props}>
      <Create {...props}>
        <PostCreateForm />
      </Create>
    </FormWrapper>
  );
}

const PostCreateForm = props => { 
  
  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        // here starts the custom form layout
        <form>
          <Box p="1em">
            <Box pl="24px" pr="24px">
              <Box display="flex" >
                <Box flex={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="#/posts">
                      <Icon className="fa fa-chevron-left" fontSize="small" /> Back
                    </Link>
                    <Typography color="textPrimary">{props.options && props.options.title ? props.options.title : props.resource}</Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <TextInput source="title" label="Title" validate={required()} fullWidth />
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <TextInput source="description" label="Description" defaultValue={""} multiline rows="3" fullWidth />
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px" pt="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <RichTextInput toolbar={toolbarOptions} rows="10" source="detail" label="Detail" validate={required()} fullWidth />
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px" pt="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <ReferenceArrayInput reference="categories" source="categories" validate={required()} fullWidth>
                    <SelectArrayInput>
                        <ChipField source="name" />
                    </SelectArrayInput>
                  </ReferenceArrayInput>
                </Box>
                <Box pr="12px" flex={1}>
                  <DateTimeInput source="published_at" fullWidth />
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px" pt="24px">
              <Box display="flex">
                <Box pr="12px" flex={2}>
                  <ImageInput source="feature_image" label="Featured Image" validate={required()} accept="image/*">
                    <ImageField source="url" title="name" />
                  </ImageInput>
                  <Typography variant="caption" display="block" gutterBottom>
                    Recommended size: 600 x 600
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px" pt="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <JsonInput source="options" label="Options (Json)" jsonString={false} fullWidth />
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px" pt="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <TextInput source="meta_keywords" label="Meta Keywords" validate={required()} fullWidth />
                </Box>
                <Box pr="12px" flex={1}>
                  <TextInput source="meta_description" label="Meta Description" validate={required()} fullWidth />
                </Box>
              </Box>
            </Box>
            <Box pl="24px" pr="24px" pt="24px">
              <Box display="flex">
                <Box pr="12px" flex={1}>
                  <BooleanInput label="Status" source="status" defaultValue={true} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Toolbar
            record={formProps.record}
            basePath={formProps.basePath}
            invalid={formProps.invalid}
            handleSubmit={formProps.handleSubmit}
            saving={formProps.saving}
            pristine={formProps.pristine}
            resource="posts"
            mutationMode="pessimistic"
          />
        </form>
      )}
    />
  );
}

export const PostEdit = props => {
  return (
    <FormWrapper name="Edit Post" {...props}>
      <Edit mutationMode="pessimistic" {...props}>
        <PostEditForm />
      </Edit>
    </FormWrapper>
  );
}

const PostEditForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex" >
              <Box flex={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href="#/posts">
                    <Icon className="fa fa-chevron-left" fontSize="small" /> Back
                  </Link>
                  <Typography color="textPrimary">{props.options && props.options.title ? props.options.title : props.resource}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="title" label="Title" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="slug" label="Slug" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="description" label="Description" defaultValue={""} multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <RichTextInput toolbar={toolbarOptions} rows="10" source="detail" label="Detail" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <ReferenceArrayInput reference="categories" source="categories" validate={required()} fullWidth>
                  <SelectArrayInput>
                      <ChipField source="name" />
                  </SelectArrayInput>
                </ReferenceArrayInput>
              </Box>
              <Box pr="12px" flex={1}>
                <DateTimeInput source="published_at" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={2}>
                <ImageInput source="feature_image" format={formatUrl} label="Feature Image" validate={required()} accept="image/*">
                  <ImageField source="url" title="name" />
                </ImageInput>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended size: 600 x 600
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <JsonInput source="options" label="Options (Json)" jsonString={false} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="meta_keywords" label="Meta Keywords" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="meta_description" label="Meta Description" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <BooleanInput label="Status" source="status" defaultValue={true} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="posts"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const PostShow = (props) => {
  const classes = customStyle();

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
      <Show {...props}>
        <BoxedShowLayout>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <TextField source="id" />
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <TextField source="title" label="Title"/>
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <TextField source="slug" label="Slug" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <RichTextField source="description" label="Description" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <RichTextField source="detail" label="Detail" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <ReferenceArrayField label="Categories" reference="categories" source="categories">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <ImageField source="feature_image" title="name" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <TextField source="meta_keywords" label="Meta Keywords"/>
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <TextField source="meta_description" label="Meta Description" />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox pr="12px" flex={1}>
              <Typography className={`${classes.label}`} variant="caption" display="block" gutterBottom>Options</Typography>
              <JsonField source="options" jsonString={false} label="Options (Json)" />
            </RaBox>
            <RaBox pr="12px" flex={1}>
              <BooleanField source="status" label="Status" />
            </RaBox>
          </RaBox>
        </BoxedShowLayout>
      </Show>
    </BoxedShowLayout>
  );
}

export const PostFilter= props => (
  <Filter { ...props }>
    <TextInput label="Search" source="keyword" alwaysOn />
  </Filter >
);

function formatUrl(value) {
  if (!value ||  typeof value === "string") {
    return { url: value };
  } else {
    return value;
  }
}
