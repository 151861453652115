import * as React from "react";
import {
  required,
  List, 
  Datagrid, 
  EditButton,
  Show,
  Edit,
  Create,
  Filter,
  TextField,
  TextInput,
  BulkDeleteButton,
  FunctionField,
  FormWithRedirect,
  Toolbar,
  EmailField,
  DateField
} from 'react-admin';
import { Box } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Breadcrumb from '../components/breadcrumb';
import FormWrapper from "../components/formWrapper";

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

export const ContactList = props => {
  const { options } = props;

  return (
    <BoxedShowLayout>
      <Breadcrumb title={ options.title ? options.title : props.resource} />
      <RaBox display="flex">
        <RaBox flex={12}>
          <List {...props} filters={<ContactFilter />} title={props.title} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
            <FunctionField
                sortBy="firstname"
                label="Full Name"
                render={record => `${record.lastname} ${record.firstname}`}
            />
              <EmailField source="email" label="Email" />
              <TextField source="phone" label="Phone" />
              <DateField source="createdAt" showTime />
              <EditButton />
            </Datagrid>
          </List>
        </RaBox>
      </RaBox>
    </BoxedShowLayout>
  )
};

export const ContactCreate = props => {
  return (
    <FormWrapper name="Add Contact" {...props}>
      <Create {...props}>
        <ContactCreateForm />
      </Create>
    </FormWrapper>
  );
}

const ContactCreateForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="lastname" label="Lastname" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="firstname" label="Firstname" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput type="email" source="email" label="Email" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="phone" label="Phone" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="message" label="Message" defaultValue={""} multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="contacts"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const ContactEdit = props => {
  return (
    <FormWrapper name="Edit Contact" {...props}>
      <Edit mutationMode="pessimistic" {...props}>
        <ContactEditForm />
      </Edit>
    </FormWrapper>
  );
}

const ContactEditForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // here starts the custom form layout
      <form>
        <Box p="1em">
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="lastname" label="Lastname" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="firstname" label="Firstname" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px" pt="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput type="email" source="email" label="Email" validate={required()} fullWidth />
              </Box>
              <Box pr="12px" flex={1}>
                <TextInput source="phone" label="Phone" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box pl="24px" pr="24px">
            <Box display="flex">
              <Box pr="12px" flex={1}>
                <TextInput source="message" label="Message" defaultValue={""} multiline rows="3" fullWidth />
              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          pristine={formProps.pristine}
          resource="contacts"
          mutationMode="pessimistic"
        />
      </form>
    )}
  />
);

export const ContactShow = (props) => (
  <BoxedShowLayout>
    <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
    <Show {...props}>
      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox pr="12px" flex={1}>
            <TextField source="id" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="lastname" label="Lastname"/>
          </RaBox>
          <RaBox pr="12px" flex={1}>
            <TextField source="firstname" label="Firstname"/>
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <EmailField source="email" label="Email" />
          </RaBox>
          <RaBox pr="12px" flex={1}>
            <TextField source="phone" label="Phone" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox pr="12px" flex={1}>
            <TextField source="message" label="Message" />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </Show>
  </BoxedShowLayout>
);

export const ContactFilter= props => (
  <Filter { ...props }>
    <TextInput label="Search" source="keyword" alwaysOn />
  </Filter >
);
