export const NO_OF_GUESTS_CHOICE = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
    { id: 13, name: "13" },
    { id: 14, name: "14" },
    { id: 15, name: "15" }
];

export const AVAILABLE_CHOICE = [
    { id: true, name: 'Available' },
    { id: false, name: 'Unavailable' },
];

export const OCCASION_CHOICE = [
    { id: 'Holiday', name: 'Holiday'},
    { id: 'Birthday', name: 'Birthday'},
    { id: 'Weekend', name: 'Weekend'},
];

export const SETTINGS_CHOICE = [
    { id: 'text', name: 'Text' },
    { id: 'number', name: 'Number' },
    { id: 'image', name: 'Image' },
    { id: 'json', name: 'JSON' },
    { id: 'html', name: 'Rich Text' },
];

export const RESERVATIONS_STATUS = {
    New: 'New',
    Contacted: 'Contacted',
    BookedSection: 'Booked Section',
    AddedToGuestList: 'Added to GuestList'
};

export const BOOKING_TYPE = {
    EventBooking: 'event_booking',
    OpenPrice: 'open_price',
    PaymentLink: 'payment_link'
};

export const GRATUITY_FEE = 0;
export const PLATFORM_FEE = 0.05;
export const STRIPE_FEE = 0.3;
export const DEFAULT_TIMEZONE = 'America/New_York';

export const ENV = process.env.REACT_APP_ENV ||'DEVELOPMENT';
export let FRONTEND_BASE_URL = (process.env.REACT_APP_ENV === 'DEVELOPMENT' ? 'https://dev.nextbrand.vn' : (process.env.REACT_APP_ENV === 'PRODUCTION' ? 'https://nextbrand.vn' : 'http://localhost:8000'));