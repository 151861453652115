import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_BACKEND_URL ||'http://localhost:8000';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const access_token = localStorage.getItem('access_token');
    options.headers.set('Authorization', `Bearer ${access_token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider =  {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            if (resource === 'users') {
                delete json.password;
            }
           return {
                data: json,
            }
         }),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
        }));
    },  

    update: (resource, params) => {
        let imageItems = [];
        let featureImage;
        let images;
        let formerImages;

        if (resource === 'settings') {
            if (params.data.type === 'image') {
                let value = params.data.value;
                if(value.rawFile && value.rawFile instanceof File) {
                    value['key'] = 'value';
                imageItems.push(value);
                }
            }
        }
        if (params.data.feature_image) {
            featureImage = params.data.feature_image;
            if(featureImage.rawFile && featureImage.rawFile instanceof File) {
                featureImage['key'] = 'feature_image';
                imageItems.push(featureImage);
            }
        }
        if (params.data.images) {
            images =  params.data.images.filter(
                p => p.rawFile && p.rawFile instanceof File
            );
            
            if(images.length) {
                images = images.map(image => ({ ...image, key: 'images' }));
                imageItems.push(...images);
                formerImages = params.data.images.filter(
                    image => !(image.rawFile instanceof File)
                );
            }
        }
        
        if (imageItems.length > 0) {
            return new Promise((resolve, reject) => 
                Promise.all([...imageItems.map(convertFileToBase64)])
                .then((res) => {
                    const items = {};

                    const feature = res.find(image => image.key === 'feature_image');
                    if(feature) {
                        items['feature_image'] = feature;
                    }
                    if(images && images.length) {
                        
                        items['images'] = res.filter(image => image.key === 'images');
                        if(formerImages.length) {
                            items['former_images'] = formerImages;
                        }
                    }
                    const value = res.find(image => image.key === 'value');
                    if(value) {
                        items['value'] = value;
                    }
                    
                    resolve(
                        httpClient(`${apiUrl}/${resource}/${params.id}`, {
                            method: 'PUT',
                            body: JSON.stringify(Object.assign(params.data, items)),
                        }).then(({ json }) => ({ data: json }))
                    )}
                )
                .catch(error => reject(error))
            )
        } else {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }))
        }
    },
    popupUpdate: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/popup-update/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        let imageItems = [];
        let featureImage;
        let images;
        if (resource === 'settings') {
            if (params.data.type === 'image') {
                let value = params.data.value;
                value['key'] = 'value';
                imageItems.push(value);
            }
        }

        if (params.data.type == null || params.data.type !== 'clone') {
            if (params.data.feature_image) {
                featureImage = params.data.feature_image;
                featureImage['key'] = 'feature_image';
                imageItems.push(featureImage);
            }
            if (params.data.images) {
                images =  params.data.images.filter(
                    p => p.rawFile && p.rawFile instanceof File
                );
                
                if(images.length) {
                    images = images.map(image => ({ ...image, key: 'images' }));
                    imageItems.push(...images);
                }
            }
        }
        

        if( imageItems.length > 0 ) {
            return new Promise((resolve, reject) => 
                Promise.all([...imageItems.map(convertFileToBase64)])
                .then((res) => {
                    const items = {
                        'feature_image' : res.find(image => image.key === 'feature_image'),
                        'images': res.filter(image => image.key === 'images'),
                        'value' : res.find(image => image.key === 'value'),
                    }
                    resolve(
                        httpClient(`${apiUrl}/${resource}`, {
                            method: 'POST',
                            body: JSON.stringify(Object.assign(params.data, items)),
                        }).then(({ json }) => ({
                            data: { ...Object.assign(params.data, items), id: json.id },
                        }))
                    )}
                )
                .catch(error => reject(error))
            )
        } else {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                if (resource !== 'bookings') {
                    return({
                        data: { ...params.data, id: json.id },
                    })
                } else {
                    return({
                        data: { ...params.data, id: json.booking.id, client_secret: json.client_secret },
                    })
                }
            })
        }
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
 const convertFileToBase64 = file =>
 new Promise((resolve, reject) => {
     const reader = new FileReader();
     reader.onload = () => resolve({
        'data' : reader.result,
        ...file
     });
     reader.onerror = reject;

     reader.readAsDataURL(file.rawFile);
 });

export default dataProvider;
