import * as React from "react";
import {
    required,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    FormWithRedirect,
    TextField, 
    EmailField, 
    TextInput,
    SelectInput,
    PasswordInput,
    FunctionField,
    ReferenceInput,
    Toolbar,
    ReferenceField,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { BulkDeleteButton } from 'react-admin';
import Breadcrumb from '../components/breadcrumb';
import FormWrapper from '../components/formWrapper';

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const UserList = props => (
    <BoxedShowLayout>
        <Breadcrumb title={ props.options.title ? props.options.title : props.resource} />
        <RaBox display="flex">
            <RaBox flex={12}>
                <List {...props} filters={<UserFilter />} title="User" bulkActionButtons={<BulkActionButtons />}>
                    <Datagrid rowClick="show">
                        <FunctionField
                            sortBy="firstname"
                            label="Name"
                            render={record => `${record.firstname} ${record.lastname}`}
                        />
                        <TextField source="phone" />
                        <EmailField source="email" />
                        <EditButton />
                    </Datagrid>
                </List>
            </RaBox>
        </RaBox>
    </BoxedShowLayout>
);

export const UserShow = (props) => (
    <BoxedShowLayout>
        <Breadcrumb title={ "#" + props.id } subMenu={ props.options.title ? props.options.title : props.resource} subMenuLink={ "#" + props.basePath } />
        <Show {...props}>
            <BoxedShowLayout>
                <RaBox display="flex">
                    <RaBox flex={1}>
                        <TextField source="id" />
                    </RaBox>
                </RaBox>
                <RaBox display="flex">
                    <RaBox flex={1}>
                        <TextField source="firstname" />
                    </RaBox>
                    <RaBox flex={1}>
                        <TextField source="lastname" />
                    </RaBox>
                </RaBox>
                <RaBox display="flex">
                    <RaBox flex={1}>
                        <TextField source="email" />
                    </RaBox>
                    <RaBox flex={1}>
                        <TextField source="phone" />
                    </RaBox>
                </RaBox>
                <RaBox display="flex">
                    <RaBox flex={2}>
                        <TextField source="address" />
                    </RaBox>
                    <RaBox flex={1}>
                        <TextField source="zip_code" />
                    </RaBox>
                    <RaBox flex={1}>
                        <ReferenceField label="Role" source="role" reference="roles" link={ false } >
                            <TextField source="title" />
                        </ReferenceField>
                    </RaBox>
                </RaBox>
            </BoxedShowLayout>
        </Show>
    </BoxedShowLayout>
);

const validateUserEditForm = (values) => {
    const errors = {};
    if (values.password && values.password !== '') {
        if(!values.repassword) {
            errors.repassword = 'Re-password is required';
        } else {
            if(values.password !== values.repassword) {
                errors.repassword = 'Password and re-password does not match';
            }
        }
    }
   
    return errors
};

const validateUserAddForm = (values) => {
    const errors = {};
    if (values.password && values.password !== '') {
        if(!values.repassword) {
            errors.repassword = 'Re-password is required';
        } else {
            if(values.password !== values.repassword) {
                errors.repassword = 'Password and re-password does not match';
            }
        }
    }
   
    return errors
};

const UserEditForm = props => (
    <FormWithRedirect
        {...props}
        render={formProps => (
            // here starts the custom form layout
            <form>
                <Box p="1em">
                    <Box pl="24px" pr="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={1}>
                                <TextInput source="firstname" validate={required()} fullWidth />
                            </Box>
                            <Box pl="12px" flex={1}>
                                <TextInput source="lastname" validate={required()} fullWidth />
                            </Box>
                        </Box>
                    </Box>
                    <Box pl="24px" pr="24px" pt="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={2}>
                                <TextInput source="email" validate={required()} fullWidth />
                            </Box>
                            <Box pl="12px" flex={1}>
                                <TextInput source="phone" validate={required()} fullWidth />
                            </Box>
                            <Box pl="12px" flex={1}>
                                <ReferenceInput source="role" reference="roles">
                                    <SelectInput optionText="title" optionValue="id" fullWidth />
                                </ReferenceInput>
                            </Box>
                        </Box>
                    </Box>
                    <Box pl="24px" pr="24px" pt="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={1}>
                                <PasswordInput label="Password" source="password" fullWidth />
                            </Box>
                            <Box pr="24px" flex={1}>
                                <PasswordInput label="Re password" source="repassword" fullWidth />
                            </Box>
                            <Box pr="12px" flex={2}>
                                <div />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                    <Toolbar
                        record={formProps.record}
                        basePath={formProps.basePath}
                        invalid={formProps.invalid}
                        handleSubmit={formProps.handleSubmit}
                        saving={formProps.saving}
                        pristine={formProps.pristine}
                        resource="users"
                        mutationMode="pessimistic"
                    />
            </form>
        )}
    />
);

export const UserEdit = props => {
    return (
        <FormWrapper name="Edit User" {...props}>
            <Edit mutationMode="pessimistic" {...props}>
                <UserEditForm validate={validateUserEditForm} />
            </Edit>
        </FormWrapper>
    );
}

const UserCreateForm = props => (
    <FormWithRedirect
        {...props}
        render={formProps => (
            // here starts the custom form layout
            <form>
                <Box p="1em"><Box pl="24px" pr="24px">
                    <Box display="flex">
                        <Box pr="12px" flex={1}>
                            <TextInput source="firstname" validate={required()} fullWidth />
                        </Box>
                        <Box pl="12px" flex={1}>
                            <TextInput source="lastname" validate={required()} fullWidth />
                        </Box>
                    </Box>
                    </Box>
                    <Box pl="24px" pr="24px" pt="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={2}>
                                <TextInput source="email" validate={required()} fullWidth />
                            </Box>
                            <Box pl="12px" flex={1}>
                                <TextInput source="phone" validate={required()} fullWidth />
                            </Box>
                            <Box pl="12px" flex={1}>
                                <ReferenceInput source="role" reference="roles">
                                    <SelectInput optionText="title" optionValue="id" fullWidth />
                                </ReferenceInput>
                            </Box>
                        </Box>
                    </Box>
                    <Box pl="24px" pr="24px" pt="24px">
                        <Box display="flex">
                            <Box pr="12px" flex={1}>
                                <PasswordInput label="Password" source="password" fullWidth />
                            </Box>
                            <Box pr="24px" flex={1}>
                                <PasswordInput label="Re password" source="repassword" fullWidth />
                            </Box>
                            <Box pr="12px" flex={2}>
                                <div />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Toolbar
                    record={formProps.record}
                    basePath={formProps.basePath}
                    invalid={formProps.invalid}
                    handleSubmit={formProps.handleSubmit}
                    saving={formProps.saving}
                    pristine={formProps.pristine}
                    resource="users"
                    mutationMode="pessimistic"
                />
            </form>
        )}
    />
);

export const UserCreate = props => {
    return (
        <FormWrapper name="Add User" {...props}>
            <Create {...props}>
                <UserCreateForm validate={validateUserAddForm} />
            </Create>
        </FormWrapper>
    );
}

export const UserFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <TextInput label="Email" source="email" />
        <ReferenceInput source="role" reference="roles">
            <SelectInput optionText="title" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);