import * as React from "react";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GalleryIcon from '@material-ui/icons/ViewCarousel';
import PageIcon from '@material-ui/icons/Pages';
import SettingsIcon from '@material-ui/icons/Settings';
import ClassIcon from '@material-ui/icons/Class';
import ArticleIcon from '@material-ui/icons/PostAdd';
import WidgetsIcon from '@material-ui/icons/Widgets';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Admin, Resource } from 'react-admin';
import { loadCSS } from 'fg-loadcss';
import { UserList, UserEdit, UserCreate, UserShow } from './resources/users';
import { GalleryList, GalleryCreate, GalleryEdit, GalleryShow } from './resources/galleries';
import { PageEdit, PageList, PageShow, PageCreate } from './resources/pages';
import { SettingCreate, SettingEdit, SettingList, SettingShow } from './resources/settings';
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from './resources/categories';
import { PostCreate, PostEdit, PostList, PostShow } from './resources/posts';
import { BlockCreate, BlockEdit, BlockList, BlockShow } from './resources/blocks';
import { ContactCreate, ContactEdit, ContactList, ContactShow } from './resources/contacts';
import { ProjectCreate, ProjectEdit, ProjectList, ProjectShow } from './resources/projects';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import theme from './components/theme';
import Menu from './components/menu';
import CustomLayout from "./components/layout";

const App = () => {
  //Load fontawesome
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const isAdmin = (permission) => permission === 'admin';
  const isModerator = (permission) => permission === 'admin' || permission === 'moderator';

  return (
    <Admin title="Nextbrand Admin Portal" menu={Menu} layout={CustomLayout} dataProvider={dataProvider} authProvider={authProvider} theme={theme}>
      {
        permission => [
          isAdmin(permission) && <Resource name="users" options={{ title: "Users" }} list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} icon={SupervisedUserCircleIcon} />,
          isAdmin(permission) && <Resource name="contacts" options={{title: "Contacts"}} list={ContactList} edit={ContactEdit} create={ContactCreate} show={ContactShow} icon={RecentActorsIcon} />,
          isModerator(permission) && <Resource name="galleries" options={{title: "Galleries"}} list={GalleryList} edit={GalleryEdit} create={GalleryCreate} show={GalleryShow} icon={GalleryIcon} />,
          isModerator(permission) && <Resource name="projects" options={{title: "Projects"}} list={ProjectList} edit={ProjectEdit} create={ProjectCreate} show={ProjectShow} icon={AssignmentIcon} />,
          isModerator(permission) && <Resource name="pages" options={{title: "Pages"}} list={PageList} edit={PageEdit} create={PageCreate} show={PageShow} icon={PageIcon} />,
          isModerator(permission) && <Resource name="categories" options={{title: "Categories"}} list={CategoryList} edit={CategoryEdit} create={CategoryCreate} show={CategoryShow} icon={ClassIcon} />,
          isModerator(permission) && <Resource name="posts" options={{title: "Posts"}} list={PostList} edit={PostEdit} create={PostCreate} show={PostShow} icon={ArticleIcon} />,
          isModerator(permission) && <Resource name="blocks" options={{title: "Blocks"}} list={BlockList} edit={BlockEdit} create={BlockCreate} show={BlockShow} icon={WidgetsIcon} />,
          isAdmin(permission) && <Resource name="settings" options={{title: "Settings"}} list={SettingList} edit={SettingEdit} create={SettingCreate} show={SettingShow} icon={SettingsIcon} />,
          <Resource name="types" />,
          <Resource name="roles" />,  
          <Resource name="status" />      
        ]
      }
    </Admin >
  )
};

export default App;
